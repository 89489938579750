import React, { useEffect, useState } from 'react';
import { BoxWrapper } from '../../../style';
import congratulations from "../../../images/congratulations.gif";
import { useNavigate } from 'react-router-dom';
import { getStorage } from '../../../Utils/common';
import { Helmet } from "react-helmet";

function ThankYou() {
  const navigate = useNavigate();
  const [progressBar, setProgressBar] = useState(getStorage('percent'));

  useEffect(() => {
    // Function to format the current date and time as a unique ID
    const generateClickId = () => {
      const now = new Date();
      return `${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}_${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}${now.getSeconds().toString().padStart(2, '0')}`;
    };

    // Trigger Facebook Pixel on component mount
    const sendFacebookPixelEvent = async () => {
      try {
        const response = await fetch(
          `https://www.facebook.com/tr?id=856557663119830&ev=PageView&noscript=1`
        );

        if (!response.ok) {
          console.error('Facebook Pixel event not sent: ', response.statusText);
        }
      } catch (error) {
        console.error('Error triggering Facebook Pixel: ', error);
      }
    };

    // Trigger Whistle Pixel of valueleaf
    const sendWhistlePixel = async () => {
      const clickId = generateClickId(); // Use the generated click_id
      const revenue = '10.00'; // Example revenue value
      try {
        const pixelUrl = `https://utils.follow.whistle.mobi/pixel.php?linkid=${clickId}&revenue=${revenue}&goal_name=Thankyou_page`;
        const response = await fetch(pixelUrl);
        if (!response.ok) {
          console.error('Whistle Pixel not sent: ', response.statusText);
        } else {
          console.log('Whistle Pixel triggered successfully with click_id:', clickId);
        }
      } catch (error) {
        console.error('Error triggering Whistle Pixel: ', error);
      }
    };

    sendFacebookPixelEvent();
    sendWhistlePixel();

  }, []); // Empty dependency array to ensure it runs only once when the component mounts

  return (
    <div>
      <Helmet>
        {/* value leaf pixel  */}
        <script>
          {`
            gtag('event', 'conversion', {'send_to': 'AW-16745229237/A4TuCN6El_IZELXX37A-'});
          `}
        </script>
        {/* end valueleaf pixel */}

        {/* Intexm pixel */}

        <script>
          {`
          gtag('event', 'conversion', {'send_to': 'AW-11162196353/asVaCMrNi_YZEIGzxsop'});
            `}
          </script>

{/* End Intexm pixel */}


        <script>
          {`
          gtag('event', 'conversion', {
              'send_to': 'AW-16559892329/e17lCOSB8cwZEOnOr9g9',
              'value': 1.0,
              'currency': 'INR'
          });
          `}
       </script>
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '856557663119830');
            fbq('track', 'SubmitApplication');
          `}
        </script>
        {/* Intexm pixel */}
        <script>
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '684971346895514');
          fbq('track', 'CompleteRegistration');
          `}
          </script>
      </Helmet>

      <iframe
        src={`https://affiliates.adsplay.in/post_install_custom_event1.php?pgmid=3421268&&eventname=disbursal`}
        style={{ display: 'none', width: '1px', height: '1px', border: 'none' }}
        allowTransparency
      />

      <img height="1" width="1" style={{ display: "none" }}
        src="https://www.facebook.com/tr?id=546967787828750&ev=PageView&noscript=1"
      />

      <BoxWrapper className="w100 gray">
        <div
          className="formmainBox flex"
          style={{
            backgroundImage: `url(${congratulations})`,
            backgroundSize: 'cover',
            height: '615px',
            width: '100%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div className="left">
            {/* Any other content you want to show */}
          </div>
        </div>
      </BoxWrapper>
    </div>
  );
}

export default ThankYou;
